<template id="comment_edit_form-template">
    <form @submit="submitForm">
        <div class="form-group row mb-3">
            <div class="col-12 col-md-3">
                <label class="form-label">
                    {{$t('client.comment.client_com_status')}}
                </label>
            </div>
            <div class="col-12 col-md-9">
                <select :class="{ 'is-invalid': validation.client_com_status != '' }" v-model="item.client_com_status"
                    class="form-select" @change="changeStatus">
                    <option value="open">nie wykonano</option>
                    <option value="close">wykonano</option>
                </select>
                <div class="invalid-feedback">{{ validation.client_com_status }}</div>
            </div>
        </div>
        <div class="form-group row mb-3">
            <div class="col-12 col-md-3">
                <label class="form-label">
                    {{$t('client.comment.client_com_title')}}
                </label>
            </div>
            <div class="col-12 col-md-9">
                <input type="text" class="form-control" :class="{ 'is-invalid': validation.client_com_title != '' }"
                    v-model="item.client_com_title">
                <div class="invalid-feedback">{{ validation.client_com_title }}</div>
            </div>
        </div>
        <div class="form-group row mb-3">
            <div class="col-12 col-md-3">
                <label class="form-label">
                    {{$t('client.comment.client_com_body')}}
                </label>
            </div>
            <div class="col-12 col-md-9">
                <textarea rows="6" type="text" class="form-control" :class="{ 'is-invalid': validation.client_com_body != '' }"
                    v-model="item.client_com_body"></textarea>
                <div class="invalid-feedback">{{ validation.client_com_body }}</div>
            </div>
        </div>
        <div class="form-group row mb-3">
            <div class="col-12 col-md-3">
                <label class="form-label">
                    {{$t('client.comment.date')}}
                </label>
            </div>
            <div class="col-12 col-md-9">
                <date-picker :class="{ 'is-invalid': validation.client_com_contact_at != '' }" class=" w-100"
                    :minute-step="10" type="datetime" v-model="item.client_com_contact_at" format="YYYY-MM-DD HH:mm"
                    value-type="YYYY-MM-DD HH:mm:ss" :input-class="['form-control','w-100']"></date-picker>
            </div>
            <div class="invalid-feedback">{{ validation.client_com_contact_at }}</div>
        </div>
        <div class="form-group row mb-3">
            <div class="col-12 col-md-3">
                <label class="form-label">
                    {{$t('client.comment.client_com_nextcontact_at')}}
                </label>
            </div>
            <div class="col-12 col-md-9">
                <date-picker :class="{ 'is-invalid': validation.client_com_nextcontact_at != '' }" class=" w-100"
                    :minute-step="10" type="datetime" v-model="item.client_com_nextcontact_at" format="YYYY-MM-DD HH:mm"
                    value-type="YYYY-MM-DD HH:mm:ss" :input-class="['form-control','w-100']"></date-picker>
            </div>
            <div class="invalid-feedback">{{ validation.client_com_nextcontact_at }}</div>
        </div>
        <div class="text-center">
            <button type="submit" @click.prevent="submitForm" class="btn btn-outline-success me-2">
                {{ $t('client.button.save')}}
            </button>
            <button @click.prevent="cancelForm" class="btn btn-outline-secondary">
                {{ $t('client.button.cancel')}}
            </button>
        </div>
    </form>
</template>
<script>
import axios from '../../../api/axios';
import dayjs from 'dayjs';
import DatePicker from 'vue2-datepicker';
export default {
    name: 'CommentEditForm',
    components: {
        'date-picker':DatePicker
    },
    props: ['type', 'client_id', 'post_id'],
    data: function () {
        return {
            BASE_URL:'',
            isLoading: true,
            id: 0,
            isEdit: false,
            validation: {
                client_com_title: '',
                client_com_body: '',
                client_com_status: '',
                client_com_contact_at: '',
                client_com_nextcontact_at: '',
            },
            item: {
                client_id: 0,
                user_id: '',
                client_com_title: 'Połączenie zaplanowane',
                client_com_body: '',
                client_com_status: 'open',
                client_com_contact_at: '',
                client_com_nextcontact_at: '',
            },
            client: {},
        }
    },
    methods: {
        changeStatus: function () {
            if (this.item.client_com_status == 'close') {
                this.item.client_com_nextcontact_at = null;
            }
        },
        getPost: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + '/api/v1/client/comment/' + this.id).then(response => {
                if (response.data.client_com_nextcontact_at == '0000-00-00 00:00:00') {
                    response.data.client_com_nextcontact_at = null;
                }
                //stworzenie pustych walidacji
                vm.item = response.data;
                vm.isLoading = false;
            });
        },
        cancelForm: function () {
            this.$emit('cancel')
        },
        submitForm: function () {
            for (const property in this.validation) {
                this.validation[property] = '';
            }
            if (this.isEdit > 0) {
                this.updatePost();
            } else {
                this.createPost();
            }
        },
        handleAxiosError: function (error) {
            if (error.response.data.messages) {
                for (const property in error.response.data.messages) {
                    this.validation[property] = error.response.data.messages[property];
                }
            } else console.log(error);
        },
        createPost: function () {
            var vm = this;
            axios.post(this.BASE_URL + '/api/v1/client/comment', this.item).then(() => {
                this.$emit('ok')
            }).catch(error => {
                vm.handleAxiosError(error);
            });
        },
        updatePost: function () {

            axios.put(this.BASE_URL + '/api/v1/client/comment/' + this.id, this.item).then(() => {
                this.$emit('ok')
            }).catch(this.handleAxiosError);
        },
    },
    created() {
        this.isEdit = this.type == 'edit' ? 1 : 0; //edycja czy dodawnie
        this.id = this.post_id;
        this.item.client_id = this.client_id;
        if (this.isEdit) {
            this.getPost()
        } else {
            this.item.client_com_contact_at = dayjs().format('YYYY-MM-DD HH:mm:ss')
            // this.item.client_com_nextcontact_at = dayjs().format('YYYY-MM-DD HH:mm:ss')
        }
    }
}
</script>