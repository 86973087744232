<template>
    <div class="card" :class="variant_class+' mb-3'">
        <div class="card-header pt-2 pb-1">
            <span class="text-secondary">{{ item.client_com_title }}</span>
            <span class="label float-end" :class="'label-' + box_variant">{{ dateToPl(item.client_com_contact_at)
            }}</span>
        </div>
        <div class="card-body card-task pt-2 pb-0">
            <div class="row">
                <div class="col-sm-12 pt-1 pb-0">
                    <p class="task-detail">{{ item.client_com_body }}</p>
                </div>
            </div>
        </div>
        <hr class="m-1">
        <div class="card-body card-task pt-0 pb-2">
            <div class="task-list-table w-100 d-flex justify-content-between mt-2">
                <div>
                    <MagicoAvatar class="d-inline" :user_id="item.user_id"></MagicoAvatar> {{ item.user.name }}
                </div>
                <comment_edit_modal :client_id="item.client_com_id" @change="changeCommentModal" class="float-end">
                </comment_edit_modal>
                <div v-if="item.client_com_nextcontact_at != '0000-00-00 00:00:00'"
                    v-b-tooltip="generateTooltip(item, box_variant)" class="float-end  btn material-icons-outlined"
                    :class="'text-' + box_variant"><span class="material-icons-outlined">notifications</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import MagicoAvatar from '../../MagicoAvatar.vue';
import CommentEditModal from './CommentEditModal.vue';

export default {
    name: 'CommentIndexItemBox',
    components: {
    "comment_edit_modal": CommentEditModal,
    MagicoAvatar
},
    props: ['post'],
    data: function () {
        return {
            box_variant: 'primary',
            variant_class: '',
            box_url: '',
        }
    },
    computed: {},
    methods: {
        changeCommentModal() {
            // MAGICO_PANEL.alert('success', 'Zmieniono kontakt');
            this.$emit('change');
        },
        dateToPl(date) {
            var newdate = dayjs(date);
            if (newdate.isValid()) return newdate.format('DD MMMM YYYY HH:mm', "PL-pl")
            return '';
        },
        generateTooltip: function () {
            var text = '';
            text = 'Następne połączenie <br />' + this.dateToPl(this.item.client_com_nextcontact_at);
            var a = {
                title: text,
                placement: 'bottom',
                variant: this.box_variant,
                'html': true
            }
            return a;
        },
    },
    created() {
        this.item = this.post
        this.variant_class = this.post.client_com_status == 'open' ? 'border-1 border-primary' : 'border-1 border-success';
        this.box_variant = this.post.client_com_status == 'open' ? 'primary' : 'success';
    }
}
</script>