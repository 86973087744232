<template id="comment_add_modal-template">
    <div>
        <button @click.prevent="openModal" type="button" class="btn btn-outline-primary w-100">Nowy kontakt</button>
        <b-modal v-model="show" title="Dodaj kontakt" @ok="saveModal()" hide-footer>
            <comment_edit_form :client_id="id" type="create" @ok="saveModal" @cancel="closeModal"></comment_edit_form>
        </b-modal>
    </div>
</template>
<script>
import MagicoModal from '../../MagicoModal.vue';
import CommentEditForm from './CommentEditForm.vue'
export default {
    name: 'CommentAddModal',
    components: {
        'b-modal': MagicoModal,
        'comment_edit_form': CommentEditForm
    },
    props: ['post', 'client_id'],
    data: function () {
        return {
            show: false,
            item: '',
            id: '',
        }
    },
    computed: {},
    methods: {
        openModal() {
            this.show = true;
        },
        closeModal() {
            this.show = false;
        },
        saveModal() {
            this.$emit('change', this.item);
            this.show = false;
        }
    },
    created() {
        this.id = this.client_id
    }
}
</script>