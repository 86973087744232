<template id="comment_edit_modal-template">
    <div>
        <button @click.prevent="openModal" class="btn p-2 btn-outline-secondary mr-1" title="Edytuj"><i
                class="material-icons-outlined">create</i></button>
        <b-modal v-model="show" title="Edytuj kontakt" @ok="saveModal()" hide-footer>
            <comment_edit_form v-if="id" :client_id="id" :post_id="id" type="edit" @ok="saveModal" @cancel="closeModal">
            </comment_edit_form>
        </b-modal>
    </div>
</template>
<script>
import MagicoModal from '../../MagicoModal.vue';
import CommentEditForm from './CommentEditForm.vue'

export default {
    name: 'CommentEditModal',
    components: {
        'b-modal': MagicoModal,
        'comment_edit_form': CommentEditForm
    },
    props: ['post', 'client_id'],
    data: function () {
        return {
            show: false,
            item: '',
            id: '',
        }
    },
    computed: {},
    methods: {
        openModal() {
            this.show = true;
        },
        closeModal() {
            this.show = false;
        },
        saveModal() {
            this.$emit('change', this.item);
            this.show = false;
        }
    },
    created() {
        this.id = this.client_id
    }
}
</script>