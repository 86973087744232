<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row" v-if="!isLoading">
            <div class="col-12 col-md-6 ">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between">
                        <h5>Dane podstawowe</h5>
                        <div class="card-header-right">
                            <button @click="clickEdit(item)"
                                class="btn btn-icon-sm btn-outline-secondary me-1 off_tooltip_off" title="Edytuj"><i
                                    class="material-icons-outlined">create</i></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_nip') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_nip }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_name_short') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_name_short }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_name') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_name }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_stage') }}
                            </div>
                            <div class="col-12 col-md-7">{{ slownikClientStage(item.client_stage) }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_group') }}
                            </div>
                            <div class="col-12 col-md-7">{{ slownikClientGroup(item.client_group) }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_type') }}
                            </div>
                            <div class="col-12 col-md-7">
                                <span class="me-2 badge badge-secondary" v-for="i,ki in item.g_client_type" :key="ki">{{ i
                                }}</span>


                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_user_id') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.user ? item.user.name : '' }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_economic_form') }}
                            </div>
                            <div class="col-12 col-md-7">{{ slownikClientEconomicForm(item.client_economic_form) }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_regon') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_regon }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_krs') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_krs }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_wojewodztwo') }}
                            </div>
                            <div class="col-12 col-md-7">{{ slownikWojewodztwo(item.client_wojewodztwo) }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_postcode') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_postcode }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_city') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_city }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_address') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_address }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_www') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_www }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_want_to') }}
                            </div>
                            <div class="col-12 col-md-7">
                                <span class="me-2 badge badge-primary" v-for="i,ik in item.g_client_want_to" :key="ik">{{ i
                                }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-5 form-label">
                                {{ $t('client.client_comment') }}
                            </div>
                            <div class="col-12 col-md-7">{{ item.client_comment }}</div>
                        </div>
                    </div>
                </div>
                <div class="filter-bar row">
                    <div class="col-12 col-xl-6" v-for="project,kp in projects" :key="kp">
                        <project_index_item_box :post="project" :key="project.project_id"></project_index_item_box>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5>Przedstawiciel</h5>
                        <div class="card-header-right">
                        </div>
                    </div>
                    <div class="card-body">
                        <div v-for="representative,kr in item.representative" :key="kr">
                            <ul class="media-list m-0 p-0">
                                <li class="media d-flex justify-content-between align-items-center m-b-15">
                                    <div class=" me-2 file-attach">
                                        <span class="material-icons-outlined">account_circle</span>
                                    </div>
                                    <div class="flex-fill">
                                        <b class="m-b-5 d-block text-secondary">{{ representative.client_rep_name }}</b>
                                        <div class="text-muted ps-11 pr-1">{{ representative.client_rep_phone }}
                                            {{ representative.client_rep_email }}</div>
                                    </div>
                                    <div class="float-right d-block ps-11 pr-1">{{ representative.client_rep_position }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <attachment_files_card nodelete="1" v-model="reloadFiles" v-if="item['_links'] && item['_links'].files"
                :url="item['_links'].files.href" :title="lang($t('client.client_files')"></attachment_files_c)ard> -->

            </div>
            <div class="col-12 col-md-6 filter-bar ">
                <div class="card mb-3">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="pb-0 mb-1">
                            Kontakt z klientem
                        </h5>
                        <div class="card-header-right pb-0">
                            <comment_add_modal :client_id="client_id" @change="changeCommentModal" :key="client_id">
                            </comment_add_modal>
                        </div>
                    </div>
                </div>
                <comment_index_item_box v-for="comment in comments" :post="comment" :key="comment.comment_id"
                    @change="getComments"></comment_index_item_box>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '../../api/axios';
import CommentAddModal from '../../components/client/comment/CommentAddModal.vue'
import CommentIndexItemBox from '../../components/client/comment/CommentIndexItemBox.vue';
import api from '../../api/api';
// @ is an alias to /src

export default {
    name: 'ClientShow',
    components: {
        'comment_add_modal': CommentAddModal,
        'comment_index_item_box': CommentIndexItemBox
    },
    data: function () {
        return {
            BASE_URL: api.getBaseUrl(),
            isLoading: true,
            client_id: 0,
            id: 0,
            comments: [],
            projects: [],
            slownik_client_stage: this.$store.state.slownik_client_stage,
            slownik_client_group: this.$store.state.slownik_client_group,
            slownik_client_type: this.$store.state.slownik_client_type,
            slownik_client_want_to: this.$store.state.slownik_client_want_to,
            slownik_wojewodztwo: this.$store.state.slownik_wojewodztwo,
            slownik_user: this.$store.state.slownik_user,
            slownik_client_economic_form: this.$store.state.slownik_client_economic_form,
            isEdit: false,
            representative_validation: [],
            header: {
                client_rep_name: '',
            },
            item: {},
            g_client_want_to: {},
        }
    },
    methods: {
        lang(x) {
            return x
        },
        slownikWojewodztwo(item) {
            var temp = item
            this.slownik_wojewodztwo.forEach(i => {
                if (item == i.value) temp = i.name
            })
            return temp;
        },
        convertType(item) {
            var vm = this;
            var ar = String(item.client_type).split('|');
            console.log(ar);
            vm.slownik_client_type.forEach(i => {
                // item.g_client_want_to[i.value] = i.name
                ar.forEach(y => {
                    if (y == i.value) item.g_client_type.push(i.name);
                })
            })
        },
        convertWantTo(item) {
            var vm = this;
            var ar = String(item.client_want_to).split('|');
            console.log(ar);
            vm.slownik_client_want_to.forEach(i => {
                // item.g_client_want_to[i.value] = i.name
                ar.forEach(y => {
                    if (y == i.value) item.g_client_want_to.push(i.name);
                })
            })
        },

        slownikClientEconomicForm: function (value) {
            //moveitem
            var name = '';
            this.slownik_client_economic_form.forEach(item => {
                if (item.value == value) {
                    name = item.name;
                    return
                }
            })
            return name;
        },
        slownikClientStage: function (value) {
            //moveitem
            var name = '';
            this.slownik_client_stage.forEach(item => {
                if (item.value == value) {
                    name = item.name;
                    return
                }
            })
            return name;
        },
        slownikClientType: function (value) {
            //moveitem
            var name = '';
            this.slownik_client_type.forEach(item => {
                if (item.value == value) {
                    name = item.name;
                    return
                }
            })
            return name;
        },
        slownikClientGroup: function (value) {
            //moveitem
            var name = '';
            this.slownik_client_group.forEach(item => {
                if (item.value == value) {
                    name = item.name;
                    return
                }
            })
            return name;
        },
        changeCommentModal() {
            // MAGICO_PANEL.alert('success', 'dodano nowy kontakt');
            this.getComments();
        },
        clickEdit: function (item) {
            this.$router.push('/client/' + item.client_id + '/edit');
        },
        changeCheckbox() {
            console.log(this.g_client_want_to);
        },
        // convertWantTo(itemsString) {
        //     var vm = this;
        //     var ar = itemsString.split('|');
        //     this.slownik_client_want_to.forEach(i => {
        //         ar.forEach(y => {
        //             if (y == i.value) vm.g_client_want_to[i.value] = i;
        //         })
        //     })
        // },
        getProjects: function () {
            let vm = this;
            axios.get(this.BASE_URL + 'api/v1/project', {
                params: {
                    count_task: true,
                    client_id: this.client_id,
                    project_status: 'enabled'
                }
            }).then(response => {
                vm.projects = response.data.data;
            });
        },
        getComments: function () {
            let vm = this;
            this.comments = [];
            // vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/client/comment', {
                params: {
                    orderby: 'client_com_contact_at',
                    sort: 'desc',
                    client_id: this.client_id
                }
            }).then(response => {
                if (response.data.data.client_com_nextcontact_at == '0000-00-00 00:00:00') {
                    response.data.data.client_com_nextcontact_at = null;
                }
                vm.comments = response.data.data;
                // vm.search.pages = response.data.pages;
                // vm.isLoading = false;
            });
        },
        getPost: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/client/' + this.id, {
                params: {
                    add_user: true,
                    client_com_nextcontact_at: true
                }
            }).then(response => {
                //stworzenie pustych walidacji
                this.convertWantTo(response.data.client_want_to);
                vm.header.client_name = vm.item.client_name;
                vm.item = response.data;
                vm.item.g_client_want_to = [];
                vm.item.g_client_type = [];
                console.log(vm.item);
                vm.convertWantTo(vm.item);
                vm.convertType(vm.item);

                vm.isLoading = false;
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.client_id = this.id;
        this.getPost()
        this.getComments()
        this.getProjects()
    }
}
</script>
